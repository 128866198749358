import { useAt, PATHS, useTranslations, TRANSLATIONS, LOCALES } from 'fractals'
import { LeadForm, Shared } from 'modules'
import { Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import dynamic from 'next/dynamic'
import Script from 'next/script'
// TODO: ! remove the line and fix the error
// eslint-disable-next-line
import {
  IDS,
  H1,
  FOR_WHOM_HEADING,
  COST_FORMAT_HEADING_DISTANCE,
  COST_FORMAT_HEADING_MIXED,
  COST_FORMAT_SUBHEADING_MIXED,
  LEAST_PROGRAM_CONTENTS_HEADING,
  FULL_PROGRAM_CONTENTS_HEADING,
  COST_HEADING,
  OUR_PROGRAMS,
  TProgramPageProps
} from './fractals'
import { Header } from './widgets'
import { Spinner } from '@/fractals/components'
import useYandexEcommerce, {
  YandexEcommerceProduct
} from '@/fractals/hooks/useYandexEcommerce'
import { useYandexEcommerceData } from '../shared/contexts/YandexEcommerceContext'

const DynamicLeastProgramContents = dynamic(
  () => import('./widgets/LeastProgramContents/LeastProgramContents'),
  {
    loading: () => <Spinner />
  }
)
const DynamicFullProgramContents = dynamic(
  () => import('./widgets/FullProgramContents/FullProgramContents'),
  {
    loading: () => <Spinner />
  }
)
const DynamicGraduateProfessions = dynamic(
  () => import('./widgets/GraduateProfessions/GraduateProfessions'),
  {
    loading: () => <Spinner />
  }
)
const DynamicExamFreeEnrollment = dynamic(
  () => import('./widgets/ExamFreeEnrollment/ExamFreeEnrollment'),
  {
    loading: () => <Spinner />
  }
)
const DynamicWhatWillYouLearn = dynamic(
  () => import('./widgets/WhatWillYouLearn/WhatWillYouLearn'),
  {
    loading: () => <Spinner />
  }
)
const DynamicLeastDocuments = dynamic(
  () => import('./widgets/LeastDocuments/LeastDocuments'),
  {
    loading: () => <Spinner />
  }
)
const DynamicAboutTraining = dynamic(
  () => import('./widgets/AboutTraining/AboutTraining'),
  {
    loading: () => <Spinner />
  }
)
const DynamicCorporateLead = dynamic(
  () => import('./widgets/CorporateLead/CorporateLead'),
  {
    loading: () => <Spinner />
  }
)
const DynamicTeachers = dynamic(() => import('./widgets/Teachers/Teachers'), {
  loading: () => <Spinner />
})
const DynamicStudyIs = dynamic(() => import('./widgets/StudyIs/StudyIs'), {
  loading: () => <Spinner />
})
const DynamicStudyIsForPsychology = dynamic(
  () => import('./widgets/StudyIsForPsychology/StudyIsForPsychology'),
  {
    loading: () => <Spinner />
  }
)
const DynamicForWhom = dynamic(() => import('./widgets/ForWhom/ForWhom'), {
  loading: () => <Spinner />
})
const DynamicReviews = dynamic(() => import('./widgets/Reviews/Reviews'), {
  loading: () => <Spinner />
})
const DynamicCost = dynamic(() => import('./widgets/Cost/Cost'), {
  loading: () => <Spinner />
})
const DynamicPsihologiyaIsHigherEducation = dynamic(
  () => import('./widgets/Cost/CostPsihologiyaIsHigherEducation'),
  {
    loading: () => <Spinner />
  }
)
const DynamicHero = dynamic(() => import('./widgets/Hero/Hero'), {
  loading: () => <Spinner />
})
const DynamicFAQ = dynamic(() => import('modules/shared/widgets/FAQ/FAQ'), {
  loading: () => <Spinner />
})
const DynamicDiplomas = dynamic(
  () => import('modules/shared/widgets/Diplomas/Diplomas'),
  {
    loading: () => <Spinner />
  }
)
const DynamicCommunitySupport = dynamic(
  () => import('./widgets/CommunitySupport'),
  {
    loading: () => <Spinner />
  }
)
const DynamicHowTrainingWorks = dynamic(
  () => import('./widgets/HowTrainingWorks'),
  {
    loading: () => <Spinner />
  }
)
const DynamicStudySteps = dynamic(
  () => import('modules/shared/widgets/StudySteps/StudySteps'),
  {
    loading: () => <Spinner />
  }
)

const DynamicLeadFormPsychology = dynamic(
  () => import('./widgets/LeadFormPsychology/LeadFormPsychology'),
  {
    loading: () => <Spinner />
  }
)

const ProgramPage = ({
  hero,
  diplomas,
  whatWillYouLearn,
  accentColor,
  graduateProfessions,
  leastProgramContents,
  fullProgramContents,
  program,
  questions,
  cost,
  studySteps,
  reviews,
  teachers,
  forWhom,
  studyIs,
  examFreeEnrollment,
  footer,
  seo,
  howTrainingWorksCards
}: TProgramPageProps) => {
  const [
    forWhomHeading,
    leastProgramContentsHeading,
    fullProgramContentsHeading,
    costHeading,
    costFormatHeadingDistance,
    costFormatHeadingMixed,
    costFormatSubHeadingMixed,
    companyName
  ] = useTranslations(
    FOR_WHOM_HEADING,
    LEAST_PROGRAM_CONTENTS_HEADING,
    FULL_PROGRAM_CONTENTS_HEADING,
    COST_HEADING,
    COST_FORMAT_HEADING_DISTANCE,
    COST_FORMAT_HEADING_MIXED,
    COST_FORMAT_SUBHEADING_MIXED,
    TRANSLATIONS.companyName
  )

  const { isBachelor, isMaster, isHigherEducation, isCourse } = useAt()

  const { asPath, locale } = useRouter()

  const { sendDetailEvent } = useYandexEcommerce()

  const { setYandexEcommerce } = useYandexEcommerceData()

  useEffect(() => {
    const yandexProgram: YandexEcommerceProduct = {
      id: `p${hero?.id}`,
      name: hero?.name,
      brand: hero?.category.name,
      category: hero?.faculty.name,
      price: hero?.cost.price
    }
    setYandexEcommerce(yandexProgram)
    sendDetailEvent([yandexProgram])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  const sections = [
    {
      rank: 100,
      Section: <DynamicHero h1={H1} colorVariant={accentColor} hero={hero} />
    },
    {
      rank: 200,
      Section: <DynamicForWhom heading={forWhomHeading} forWhom={forWhom} />
    },
    {
      // TODO: rename this component to CTAWithImage and apply a prop for isCourse to make it look different the way it's in Figma
      rank: (isCourse && 500) || 300,
      Section:
        hero?.faculty.slug === 'psihologiya' &&
        hero?.category?.slug === 'kursy' ? null : (
          <DynamicExamFreeEnrollment
            cost={hero?.cost?.price ? hero.cost.price : 0}
            examFreeEnrollment={examFreeEnrollment}
          />
        )
    },
    {
      rank: (isCourse && 700) || 400,
      Section: (
        <DynamicStudySteps colorVariant={accentColor} studySteps={studySteps} />
      )
    },
    {
      rank: 450,
      Section:
        hero?.faculty.slug === 'psihologiya' &&
        hero?.category?.slug === 'kursy' ? (
          <DynamicCommunitySupport />
        ) : null
    },
    {
      rank: 460,
      Section:
        hero?.faculty.slug === 'psihologiya' &&
        howTrainingWorksCards.length !== 0 ? (
          <DynamicHowTrainingWorks
            howTrainingWorksCards={howTrainingWorksCards}
          />
        ) : null
    },
    {
      rank: (isCourse && 600) || 500,
      Section:
        hero?.faculty.slug === 'psihologiya' ||
        hero?.category.slug === 'mba' ? (
          <DynamicFullProgramContents
            heading={fullProgramContentsHeading}
            fullProgramContents={fullProgramContents}
          />
        ) : (
          <DynamicLeastProgramContents
            cost={hero?.cost?.price ? hero.cost.price : 0}
            program={program}
            heading={leastProgramContentsHeading}
            colorVariant={accentColor}
            leastProgramContents={leastProgramContents}
          />
        )
    },
    {
      rank: (isCourse && 300) || 600,
      Section: (
        <DynamicWhatWillYouLearn
          whatWillYouLearn={whatWillYouLearn}
          colorVariant={accentColor}
        />
      )
    },
    {
      rank: 400,
      Section: isCourse && <DynamicAboutTraining />
    },
    {
      rank: (isCourse && 650) || 550,
      Section:
        hero?.faculty.slug === 'psihologiya' ? (
          <DynamicLeadFormPsychology />
        ) : null
    },
    {
      rank:
        hero?.faculty.slug === 'psihologiya'
          ? (isCourse && 690) || 590
          : (isCourse && 800) || 700,
      Section: (
        // hero?.faculty.slug === 'psihologiya' ? (
        //   <DynamicStudyIsForPsychology />
        // ) : (
        //   <DynamicStudyIs studyIs={studyIs} />
        // )
        <DynamicStudyIs studyIs={studyIs} />
      )
    },
    // {
    //   rank: (isCourse && 900 || 800),
    //   Section: <Shared.Video id={IDS.video} />
    // },
    {
      rank: 1000,
      Section: isCourse && <DynamicTeachers teachers={teachers} />
    },
    {
      rank: (isCourse && 1100) || 900,
      Section: <DynamicDiplomas diplomas={diplomas} />
    },
    {
      rank: 1000,
      Section: isHigherEducation && (
        <DynamicLeastDocuments colorVariant={accentColor} />
      )
    },
    {
      rank: (isCourse && 1300) || 1200,
      Section: (
        <DynamicGraduateProfessions graduateProfessions={graduateProfessions} />
      )
    },
    {
      rank: 1400,
      Section: isCourse && (
        <DynamicReviews colorVariant={accentColor} reviews={reviews} />
      )
    },
    {
      rank: (isCourse && 1500) || 1300,
      Section:
        hero?.faculty.slug === 'psihologiya' &&
        hero?.category.slug === 'bakalavriat' ? (
          <DynamicPsihologiyaIsHigherEducation
            heading={costHeading}
            costFormatHeadingDistance={costFormatHeadingDistance}
            costFormatHeadingMixed={costFormatHeadingMixed}
            costFormatSubHeadingMixed={costFormatSubHeadingMixed}
            cost={cost}
            colorVariant={accentColor}
          />
        ) : (
          <DynamicCost
            heading={costHeading}
            cost={cost}
            colorVariant={accentColor}
          />
        )
    },
    // {
    //   rank: 1600,
    //   Section: isCourse && <>Recommended programs</>
    // },
    {
      rank: 1700,
      Section: isCourse && (
        <DynamicCorporateLead cost={hero?.cost?.price ? hero.cost.price : 0} />
      )
    },
    {
      rank: (isCourse && 1800) || 1400,
      Section: (
        <DynamicFAQ
          cost={hero?.cost?.price ? hero.cost.price : 0}
          questions={questions}
        />
      )
    }
  ]
    .filter(category => category.Section)
    .sort((a, b) => a.rank - b.rank)

  const seoParams = {
    title:
      seo?.seo.metaTitle ||
      (locale === LOCALES.ru &&
        isBachelor &&
        `${hero?.name || 'Программа'}: обучение онлайн | ${
          hero?.category?.name || 'Обучение'
        } - дистанционное высшее образование `) ||
      (locale === LOCALES.ru &&
        `${hero?.name || 'Программа'}: обучение дистанционно | ${
          hero?.category?.name || 'Обучение'
        } от онлайн-института MITM`) ||
      hero?.name ||
      null,
    desc:
      seo?.seo.metaDescription ||
      (locale === LOCALES.ru &&
        isCourse &&
        `Обучение по курсу "${
          hero?.name || 'Программа'
        }" от самого инновационного ВУЗа страны - MITM.institute. Онлайн - курс дополнительного профессионального образования (ДПО) вместе с ведущими экспертами-практиками. Дистанционный формат обучения ➜ Скидка - 30%`) ||
      (locale === LOCALES.ru &&
        isMaster &&
        `Обучение по курсу "${
          hero?.name || 'Программа'
        }" от самого инновационного ВУЗа страны - MITM.institute. Вторая ступень высшего образования магистратура образование вместе с экспертами-практиками ➜ Дистанционный формат обучения. Диплом государственного образца! Скидка - 30%`) ||
      (locale === LOCALES.ru &&
        isBachelor &&
        `Обучение по курсу "${
          hero?.name || 'Программа'
        }" от самого инновационного ВУЗа страны - MITM.institute. Высшее образование (бакалавриат) вместе с экспертами-практиками ➜ Дистанционный формат обучения. Диплом государственного образца! Скидка - 30%`) ||
      hero?.description ||
      null,
    canonical: seo?.seo.canonicalURL || `${PATHS.front.root.index}${asPath}`
  }

  return (
    <>
      {hero?.slug && OUR_PROGRAMS.includes(hero?.slug) && (
        <Script
          type='text/javascript'
          async
          defer
          id='qoopler-our-programs'
          dangerouslySetInnerHTML={{
            __html: `
              (function (d, w) {
                var n = d.getElementsByTagName("script")[0],
                s = d.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = "https://qoopler.ru/index.php?ref="+d.referrer+"&page=" + encodeURIComponent(w.location.href);
                n.parentNode.insertBefore(s, n);
              })(document, window);
              `
          }}
        />
      )}
      <NextSeo
        title={seoParams.title || undefined}
        description={seoParams.desc || undefined}
        canonical={seoParams.canonical}
        openGraph={{
          url: seoParams.canonical,
          title: seoParams.title || undefined,
          description: seoParams.desc || undefined,
          images: [
            {
              url: `${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`,
              width: 512,
              height: 512,
              alt: companyName,
              type: 'image/png'
            }
          ],
          site_name: companyName
        }}
      />
      <Shared.PageContainer id={IDS.programPage}>
        <Header
          colorVariant={accentColor}
          bread={{ slug: hero?.faculty.slug }}
        />
        <main itemType='https://schema.org/Product' itemScope>
          {sections.map(({ Section }, idx) => (
            <Fragment key={`ProgramPage__section--${idx + 1}`}>
              {Section}
            </Fragment>
          ))}
          {/* <Shared.AskModal /> */}
        </main>
        <Shared.Footer footer={footer} />
      </Shared.PageContainer>
    </>
  )
}

export default ProgramPage
