/* eslint-disable import/extensions */
import React, { createContext, useContext, useMemo, useState } from 'react'
import { type YandexEcommerceProduct } from '@/fractals/hooks/useYandexEcommerce'

interface YandexEcommerceDataContextProps {
  YandexEcommerceProduct: YandexEcommerceProduct | null
  setYandexEcommerce: (
    YandexEcommerceProduct: YandexEcommerceProduct | null
  ) => void
}

const YandexEcommerceDataContext = createContext<
  YandexEcommerceDataContextProps | undefined
>(undefined)

export const useYandexEcommerceData = (): YandexEcommerceDataContextProps => {
  const context = useContext(YandexEcommerceDataContext)
  if (!context) {
    throw new Error(
      'useYandexEcommerceData must be used within a YandexEcommerceDataProvider'
    )
  }
  return context
}

interface YandexEcommerceDataProviderProps {
  children: React.ReactNode
}

export const YandexEcommerceDataProvider: React.FC<
  YandexEcommerceDataProviderProps
> = ({ children }) => {
  const [YandexEcommerceProduct, setYandexEcommerce] =
    useState<YandexEcommerceProduct | null>(null)

  const value = useMemo(
    () => ({ YandexEcommerceProduct, setYandexEcommerce }),
    [YandexEcommerceProduct]
  )

  return (
    <YandexEcommerceDataContext.Provider value={value}>
      {children}
    </YandexEcommerceDataContext.Provider>
  )
}
