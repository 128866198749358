import { useEffect } from 'react'
import { ENV } from 'fractals'
import { v4 as uuidv4 } from 'uuid'

export interface YandexEcommerceProduct {
  id?: string | null
  name?: string | null
  price?: number | null
  quantity?: number | null
  brand?: string | null
  category?: string | null
  variant?: string | null
  list?: string | null
  position?: number | null
  // Дополнительные поля при необходимости
}

type EventType =
  | 'detail'
  | 'purchase'
  | 'add'
  | 'remove'
  | 'impressions'
  | 'click'

interface ActionField {
  id?: string
  goal_id?: string
}

const useYandexEcommerce = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
    }
  }, [])

  const sendEvent = (
    eventType: EventType,
    products: YandexEcommerceProduct[],
    actionField: ActionField = {
      goal_id: ENV.ymGoalId,
      id: uuidv4()
    },
    currencyCode = 'RUB'
  ) => {
    if (window && window.dataLayer) {
      const ecommerceData: any = {
        ecommerce: {
          currencyCode,
          [eventType]: {
            products
          }
        }
      }

      if (actionField && Object.keys(actionField).length > 0) {
        ecommerceData.ecommerce[eventType].actionField = actionField
      }

      window.dataLayer.push(ecommerceData)
    } else {
      console.warn('dataLayer is not defined')
    }
  }

  const sendDetailEvent = (products: YandexEcommerceProduct[]) => {
    sendEvent('detail', products)
  }

  const sendPurchaseEvent = (
    products: YandexEcommerceProduct[],
    actionField?: ActionField
  ) => {
    sendEvent('purchase', products, actionField)
  }

  // Другие функции для дополнительных событий при необходимости

  return {
    sendDetailEvent,
    sendPurchaseEvent
    // Другие функции
  }
}

export default useYandexEcommerce
