/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import Image from 'next/image'
import { Shared } from 'modules'
import React, { useEffect, useState, useCallback } from 'react'
import Link from 'next/link'
import { useAt } from 'fractals'
import styles from './BlackFridayBanner.module.css'
import starDiscountImg from './assets/starDiscount.png'
import starDiscountDesktopImg from './assets/starDiscountDesktop.png'
import ligthningImg from './assets/lightning.png'

// Интерфейс для состояния timeLeft
interface TimeLeft {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const BlackFridayBanner = () => {
  const { isHomePage } = useAt()
  const targetDate = new Date(2024, 11, 1) // Ноябрь (месяцы в JS начинаются с 0)

  // Указываем начальное значение и тип для состояния timeLeft
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [visible, setVisible] = useState(true)

  const calculateTimeLeft = useCallback(() => {
    const now = new Date()
    const difference = targetDate.getTime() - now.getTime()

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((difference / 1000 / 60) % 60)
      const seconds = Math.floor((difference / 1000) % 60)

      return {
        days,
        hours,
        minutes,
        seconds
      }
    }

    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  }, [targetDate])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [calculateTimeLeft])

  if (!visible) {
    return null
  }

  return (
    <div className={styles.BlackFridayBannerWrapper}>
      <Image
        alt='starDiscount'
        src={starDiscountImg.src}
        width={starDiscountImg.width}
        height={starDiscountImg.height}
        className={styles.starDiscount}
      />
      <div className={styles.mainText}>
        Черная пятница! <br className={styles.mainTextDelimeterFirst} />
        Скидки на курсы <br className={styles.mainTextDelimeterSecond} />
        до 50%
        <div className={styles.subText}>
          Успейте забронировать место со скидкой!
        </div>
      </div>
      <div className={styles.starDiscountDesktopContainer}>
        <Image
          alt='starDiscountDesktop'
          src={starDiscountDesktopImg.src}
          width={starDiscountDesktopImg.width}
          height={starDiscountDesktopImg.height}
          className={styles.starDiscountDesktop}
        />
      </div>
      <div className={styles.rightSideContainer}>
        <div className={styles.timer}>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.days).padStart(2, '0')}{' '}
              <span className={styles.timerColon} />:
            </div>
            <div className={styles.timerText}>дней</div>
          </span>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.hours).padStart(2, '0')}{' '}
              <span className={styles.timerColon} />:
            </div>
            <div className={styles.timerText}>часов</div>
          </span>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.minutes).padStart(2, '0')}{' '}
              <span className={styles.timerColon} />:
            </div>
            <div className={styles.timerText}>минут</div>
          </span>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.seconds).padStart(2, '0')}
            </div>
            <div className={styles.timerText}>секунд</div>
          </span>
        </div>
        <div className={styles.ligthningContainer}>
          <Image
            alt='ligthning'
            src={ligthningImg.src}
            width={ligthningImg.width}
            height={ligthningImg.height}
            className={styles.ligthning}
          />
        </div>

        {isHomePage ? (
          <Link
            href='https://mitm-sale.ru'
            className={styles.getDiscountButton}
            aria-label='Get discount'>
            Получить скидку
          </Link>
        ) : (
          <Shared.LeadPopup
            buttonProps={{
              colorsVariant: 'beta',
              className: styles.getDiscountButton,
              children: 'Получить скидку'
            }}
          />
        )}
        <button
          className={styles.closeButton}
          onClick={() => setVisible(false)}
          aria-label='Закрыть баннер'>
          ✖
        </button>
      </div>
    </div>
  )
}

export default BlackFridayBanner
