import { LOCALES } from 'fractals'

export const TIMER_HEADING = {
  [LOCALES.en]:
    'Difficult to choose a training program? Leave a request and get advice from a manager.',
  [LOCALES.ru]:
    'Трудно выбрать программу обучения? Оставьте заявку и получите консультацию менеджера.',
  [LOCALES.uz]:
    "O'quv dasturini tanlashda muammo bormi? So'rov qoldiring va menejerdan maslahat oling.",
  [LOCALES.kk]:
    'Оқу бағдарламасын таңдауда қиындықтар туындады ма? Өтінім қалдырыңыз және менеджерден кеңес алыңыз.'
} as const

export const TIMER_BUTTON = {
  [LOCALES.en]: 'Get a consultation',
  [LOCALES.ru]: 'Получить консультацию',
  [LOCALES.uz]: 'Konsultatsiya oling',
  [LOCALES.kk]: 'Консультация алыңыз'
} as const

export const LEAVE_HEADING = {
  [LOCALES.en]:
    'Leaving already? Wait, leave an application and save 30% off your tuition.',
  [LOCALES.ru]:
    'Уже уходите? Подождите, оставьте заявку и сохраните скидку 30% на обучение.',
  [LOCALES.uz]:
    'Siz allaqachon ketyapsizmi? Kutib turing, arizangizni yuboring va treningga 30% chegirmangizni saqlang.',
  [LOCALES.kk]:
    'Сіз қазірдің өзінде кетесіз бе? Күте тұрыңыз, өтінішіңізді жіберіңіз және оқуға 30% жеңілдікті сақтаңыз.'
} as const

export const LEAVE_BUTTON = {
  [LOCALES.en]: 'Save discount',
  [LOCALES.ru]: 'Сохранить скидку',
  [LOCALES.uz]: 'Chegirmani saqlang',
  [LOCALES.kk]: 'Жеңілдікті сақтаңыз'
} as const
