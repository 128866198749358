import { sortBasedOnKey } from 'fractals'
import { fetchPrograms } from './fetchPrograms'

export const normalizeProgramsData = ({
  programsData
}: {
  programsData: Awaited<ReturnType<typeof fetchPrograms>>
}) => {
  // TODO: sort diplomas based on rank
  return (
    programsData?.map(item => ({
      id: item.id,
      name: item.name,
      description: item.description,
      slug: item.slug,
      category: {
        name: item.category?.name || null,
        slug: item.category?.slug || null,
        label: item.category?.label || null,
        diplomaLabels:
          (item.category?.diplomas &&
            sortBasedOnKey(
              item.category.diplomas.map(item2 => ({
                label: item2.label || null,
                rank: item2.rank || item2.rank === 0 ? item2.rank : null
              })),
              'rank'
            ).map(item2 => item2?.label)) ||
          null
      },
      faculty: {
        name: item.faculty?.name || null,
        slug: item.faculty?.slug || null
      },
      image: {
        url: item.image?.url || null,
        width: item.image?.width || null,
        height: item.image?.height || null,
        alternativeText: item.image?.alternativeText || null
      },
      cost: {
        price: item.cost?.price || null,
        discount: item.cost?.discount || null,
        studyDurationMonths: item.cost?.studyDurationMonths || null,
        studyDurationYears: item.cost?.studyDurationYears || null,
        discountUntil: item.cost?.discountUntil || null,
        closestEnrollment: item.cost?.closestEnrollment || null,
        studyHours: item.cost?.studyHours || null
      },
      diplomaLabels:
        (item.diplomas &&
          sortBasedOnKey(
            item.diplomas?.map(item2 => ({
              label: item2.label || null,
              rank: ((item2.rank || item2.rank === 0) && item2.rank) || null
            })),
            'rank'
          ).map(item2 => item2?.label)) ||
        null
    }))?.[0] || null
  )
}

export default normalizeProgramsData
