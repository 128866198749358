/* eslint-disable consistent-return */
import Popup from 'reactjs-popup'
import { MouseEventHandler, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { getCookie, setCookie } from 'cookies-next'
import {
  Button,
  Container,
  Div,
  H2,
  IconPlus,
  toUnit,
  useMedia,
  useTranslations
} from 'fractals'
import { TLeadPopupWithTriggersProps } from './types'
import {
  TIMER_HEADING,
  TIMER_BUTTON,
  LEAVE_HEADING,
  LEAVE_BUTTON
} from './constants'
import { TLeadFormProps } from '../LeadForm/types'

const LeadForm = dynamic<TLeadFormProps>(
  () => import('modules/shared/widgets/LeadForm/LeadForm'),
  { ssr: false }
)

export const LeadPopupWithTriggers = ({
  ...props
}: TLeadPopupWithTriggersProps) => {
  const { isTablet, isLaptop, isDesktop } = useMedia()
  const [open, setOpen] = useState(false)

  const [timerHeading, timerButton, leaveHeading, leaveButton] =
    useTranslations(TIMER_HEADING, TIMER_BUTTON, LEAVE_HEADING, LEAVE_BUTTON)

  const [formControlsNames, setFormControlsNames] = useState({
    heading: timerHeading,
    button: timerButton
  })

  const router = useRouter()
  const paramKey = 'LeadPopupWithTriggers'

  // Состояние для хранения значения GET-параметра
  const [triggerType, setTriggerType] = useState<string | undefined>()

  // Разные куки ключи для каждого триггера
  const cookieKey1min = 'LeadPopupShown1min'
  const cookieKey2min = 'LeadPopupShown2min'
  const cookieKeyWantLeave = 'LeadPopupShownWantLeave'

  // Сохраняем значение GET-параметра при монтировании
  useEffect(() => {
    const paramValue = router.query[paramKey] as string
    if (paramValue) {
      setTriggerType(paramValue) // Сохраняем параметр в state
    }
  }, [router.query])

  useEffect(() => {
    if (!triggerType) return

    // Таймер на 1 минуту
    if (triggerType === '1min' && !getCookie(cookieKey1min)) {
      const timer = setTimeout(() => {
        setOpen(true)
        setCookie(cookieKey1min, 'true') // Устанавливаем cookie для 1мин
      }, 60000) // 1 минута
      return () => clearTimeout(timer)
    }

    // Таймер на 2 минуты
    if (triggerType === '2min' && !getCookie(cookieKey2min)) {
      const timer = setTimeout(() => {
        setOpen(true)
        setCookie(cookieKey2min, 'true') // Устанавливаем cookie для 2мин
      }, 120000) // 2 минуты
      return () => clearTimeout(timer)
    }

    // Отслеживание положения курсора для триггера "wantLeave"
    if (triggerType === 'wantLeave' && !getCookie(cookieKeyWantLeave)) {
      setFormControlsNames({ heading: leaveHeading, button: leaveButton })

      const handleMouseMove = (e: MouseEvent) => {
        if (e.clientY <= 10) {
          // Если курсор близко к верхнему краю (в пределах 10px)
          setOpen(true)
          setCookie(cookieKeyWantLeave, 'true') // Устанавливаем cookie для wantLeave
        }
      }

      document.addEventListener('mousemove', handleMouseMove)
      return () => document.removeEventListener('mousemove', handleMouseMove)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerType])

  return (
    <Popup
      modal
      nested
      closeOnDocumentClick
      closeOnEscape
      lockScroll
      open={open}
      {...props}>
      {/* @ts-expect-error TODO: remove this line and fix the error */}
      {(close: MouseEventHandler) => (
        <Container>
          <Div
            styles={{
              position: 'relative',
              maxWidth: toUnit(
                ((isDesktop || isLaptop) && 910) ||
                  (isTablet && 479) ||
                  undefined
              ),
              borderRadius: toUnit(16),
              backgroundColor: 'var(--alpha)',
              color: 'var(--omega)'
            }}>
            <Button
              colorsVariant='transparent'
              sizeVariant='xs'
              onClick={close}
              styles={{
                position: 'absolute',
                top: 0,
                right: 0
              }}>
              <IconPlus colorVariant='omega' variant='cross' />
            </Button>
            <Div
              styles={{
                paddingTop: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 48) || 32
                ),
                paddingRight: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 32) || 16
                ),
                paddingBottom: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 48) || 32
                ),
                paddingLeft: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 32) || 16
                )
              }}>
              {open && (
                <LeadForm
                  styleVariant='alpha'
                  formName='FORM NAME HERE'
                  heading={<H2 variant='delta'>{formControlsNames.heading}</H2>}
                  colorsVariant='beta'
                  isSandwich
                  name
                  phoneNumber
                  email
                  isPopup
                  buttonValue={formControlsNames.button}
                  styles={{
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0
                  }}
                  withImage
                  id='LeadPopupWithTriggers'
                />
              )}
            </Div>
          </Div>
        </Container>
      )}
    </Popup>
  )
}

export default LeadPopupWithTriggers
