/**
 * @type {import('next').NextConfig}
 */

// import { PHASE_DEVELOPMENT_SERVER } from 'next/constants'
import { createSecureHeaders } from 'next-secure-headers'
// import { BundleAnalyzerPlugin } from 'webpack-bundle-analyzer'

const prod = process.env.NODE_ENV === 'production'
const dev = process.env.NODE_ENV === 'development'

const nextConfig = {
  reactStrictMode: true, // * Recommended for the `pages` directory, default in `app`.
  poweredByHeader: false, // * Remove the `X-Powered-By` header.
  swcMinify: true,
  productionBrowserSourceMaps: true,
  // webpack: (config, { isServer }) => {
  //   if (!isServer) {
  //     config.plugins.push(new BundleAnalyzerPlugin())
  //   }
  //   return config
  // },
  compiler: {
    styledComponents: true,
    removeConsole: prod
  },
  skipTrailingSlashRedirect: true,
  trailingSlash: false,
  i18n: {
    locales: ['ru', 'en', 'uz', 'kk'],
    defaultLocale: 'ru',
    localeDetection: false
  },
  images: {
    // loader: 'cloudinary',
    // path: 'https://res.cloudinary.com/mitu-institute/',
    domains: ['res.cloudinary.com'],
    remotePatterns: [
      {
        protocol: 'https',
        hostname: 'kurshub.ru',
        pathname: '/informers/**'
      }
    ]
  },
  transpilePackages: ['ui'],
  staticPageGenerationTimeout: 999,
  // experimental: {
  //   // * Required:
  //   // appDir: true,
  // },
  experimental: {
    webVitalsAttribution: ['LCP', 'FCP', 'INP', 'TTFB']
  },
  async redirects() {
    return [
      { source: '/,', destination: '/', permanent: true },
      {
        source: '/kursy/dizajner-interera',
        destination: '/dizajner-interera--kurs',
        permanent: true
      },
      {
        source: '/kursy/landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
        destination:
          '/landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo--kurs',
        permanent: true
      },
      {
        source: '/kurs',
        destination: '/kursy',
        permanent: true
      },
      {
        source: '/programmy--organizaczionnaya-psihologiya-1',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--upravlenie-personalom--upravlenie-personalom-2',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--upravlenie-proektami-1--upravlenie-proektami-3',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--psiholog-pedagog-1--prakticheskaya-psihologiya-s-dop-kvalifikacziej-pedagog-psiholog--pedagog-psiholog-1',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-proektami-1--proektnyj-menedzhment--upravlenie-proektami-3--upravlenie-proektami-1--proektnyj-menedzhment--upravlenie-proektami-3',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--sdelki-s-nedvizhimostyu',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--psihologiya-1--psihologicheskoe-konsultirovanie-1--klinicheskaya-psihologiya-1--psihologiya-semejnyh-otnoshenij--korrekczionnaya-psihologiya-s-osnovami-logopedii--yuridicheskaya-psihologiya--korporativnaya-psihologiya-1--psihologiya-upravleniya--organizaczionnaya-psihologiya--klinicheskie-osnovy-psihologicheskogo-zdorovya-cheloveka--soczialnaya-psihologiya--kouching-i-psihologicheskoe-konsultirovanie--organizaczionnaya-psihologiya-1--psihologiya--klinicheskaya-psihologiya-2--kouching-individualnoe-psihologicheskoe-konsultirovanie--prakticheskaya-psihologiya--prakticheskaya-psihologiya-s-dop-kvalifikacziej-pedagog-psiholog--prakticheskaya-psihologiya-s-dop-kvalifikacziej-psiholog-psihoterapevt--psihoanaliz-i-psihologicheskaya',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--organizaczionnaya-psihologiya--ekonomika-predpriyatij-i-organizaczij--organizaczionnyj-menedzhment--elektrooborudovanie-i-elektrohozyajstvo-predpriyatij-organizaczij-i-uchrezhdenij--organizaczionnaya-psihologiya-1--menedzhment-v-organizaczii--menedzhment-organizaczii-v-zdravoohranenii',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--graficheskij-dizajn-1--graficheskij-dizajner',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--zoopsiholog',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--psihologiya-upravleniya--upravlenie-personalom--ekonomika-i-upravlenie-v-uchrezhdeniyah-zdravoohraneniya--upravlencheskij-i-finansovyj-uchet--ekonomika-i-upravlenie-na-predpriyatii--upravlenie-malym-biznesom--upravlenie-gosudarstvennym-i-municzipalnym-sektorom--upravlenie-proektami-1--upravlenie-chelovecheskimi-resursami--upravlenie-bankovskoj-i-informaczionnoj-bezopasnostyu--upravlenie-vneshneekonomicheskoj-deyatelnostyu--upravlenie-zh-kh--upravlenie-na-transporte--upravlenie-prodazhami--upravlenie-tehnologicheskimi-innovacziyami--marketing-i-upravlenie-brendami--arbitrazhnoe-antikrizisnoe-upravlenie--yuridicheskie-aspekty-upravleniya-v-sfere-zh-kh-1--upravlenie-personalom-2--upravlenie-proektami-3--upravlenie-kachestvom--antikrizisnoe-upravlenie-1--gosudarstvennoe-i-municzipalnoe-upravlenie--upravlenie-gostinichnymi-i-restorannymi-predpriyatiyami',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--klinicheskaya-psihologiya-1--klinicheskie-osnovy-psihologicheskogo-zdorovya-cheloveka--klinicheskaya-psihologiya-2',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-personalom--upravlenie-personalom-2--upravlenie-personalom--upravlenie-personalom-2',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--internet-marketing-1--marketing-1--marketing-i-upravlenie-brendami--marketing-v-kommercheskoj-deyatelnosti--direktor-po-marketingu--internet-marketing',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--kommercziya--informaczionno-kommunikaczionnye-tehnologii-i-analitika-dannyh--marketing-v-kommercheskoj-deyatelnosti',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--psihologiya-1--psihologicheskoe-konsultirovanie-1--klinicheskaya-psihologiya-1--psihologiya-semejnyh-otnoshenij--korrekczionnaya-psihologiya-s-osnovami-logopedii',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--upravlenie-kachestvom',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--menedzhment-v-stroitelstve-1--promyshlennoe-i-grazhdanskoe-stroitelstvo--gorodskoe-stroitelstvo-i-hozyajstvo--proizvodstvo-stroitelnyh-materialov-izdelij-i-konstrukczij--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-gosudarstvennym-i-municzipalnym-sektorom--gosudarstvennoe-i-municzipalnoe-upravlenie',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--konfliktologiya',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--ekonomika-predpriyatij-i-organizaczij--ekonomika-i-ekonomicheskaya-bezopasnost-predpriyatiya--ekonomika-i-logistika-predpriyatiya--ekonomika-i-upravlenie-na-predpriyatii--predprinimatelstvo--menedzhment-gostinichnyh-i-restorannyh-predpriyatij--elektrooborudovanie-i-elektrohozyajstvo-predpriyatij-organizaczij-i-uchrezhdenij',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-proektami-1--upravlenie-proektami-3--upravlenie-proektami-1--upravlenie-proektami-3',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--ekonomika-predpriyatij-i-organizaczij--ekonomika-i-ekonomicheskaya-bezopasnost-predpriyatiya--ekonomika-i-logistika-predpriyatiya--ekonomika-i-upravlenie-na-predpriyatii--predprinimatelstvo--prikladnaya-informatika-i-matematika--prikladnaya-informatika-v-ekonomike--razrabotka-kompyuternyh-igr-i-multimedijnyh-prilozhenij--menedzhment-gostinichnyh-i-restorannyh-predpriyatij--elektrooborudovanie-i-elektrohozyajstvo-predpriyatij-organizaczij-i-uchrezhdenij--razrabotka-i-dizajn-veb-prilozhenij',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--oczenka-biznesa-i-riskov--upravlenie-malym-biznesom--informaczionnoe-obespechenie-biznes-proczessov--biznes-analitika--kouching-psihologicheskoe-biznes-konsultirovanie',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--psiholog-konsultant',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-chelovecheskimi-resursami--menedzhment-gostinichnyh-i-restorannyh-predpriyatij',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-prodazhami--product-menedzher--menedzher-prodazham--rukovoditel-otdela-prodazh--upravlenie-prodazhami--product-menedzher--menedzher-prodazham--rukovoditel-otdela-prodazh',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--internet-marketing-1--marketing-1--marketing-i-upravlenie-brendami--marketing-v-kommercheskoj-deyatelnosti--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo--direktor-po-marketingu--internet-marketing',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--ekonomika-i-logistika-predpriyatiya',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--nutricziolog',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--psihologiya-1--psihologicheskoe-konsultirovanie-1--klinicheskaya-psihologiya-1',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--psihologiya-1--psihologicheskoe-konsultirovanie-1--klinicheskaya-psihologiya-1--psihologiya--kurs',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--menedzhment-v-neftegazovom-komplekse--menedzhment-v-obrazovanii--menedzhment-v-zdravoohranenii--menedzhment-v-mashinostroenii--menedzhment-v-stroitelstve-1--menedzhment-v-energetike--menedzhment-v-smi-i-reklame--menedzhment-v-turizme--menedzhment-v-organizaczii--menedzhment-v-neftegazovom-komplekse-1',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--menedzhment-v-neftegazovom-komplekse--menedzhment-v-neftegazovom-komplekse-1',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--graficheskij-dizajn-1--razrabotka-i-dizajn-veb-prilozhenij--graficheskij-dizajner--dizajner-interera--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--graficheskij-dizajner--dizajner-interera',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-zh-kh--yuridicheskie-aspekty-upravleniya-v-sfere-zh-kh-1',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--upravlenie-gosudarstvennym-i-municzipalnym-sektorom',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--razrabotka-i-dizajn-veb-prilozhenij--graficheskij-dizajn-1--graficheskij-dizajner--dizajner-interera--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--prikladnaya-informatika-i-matematika--prikladnaya-informatika-v-ekonomike--informaczionno-kommunikaczionnye-tehnologii-i-analitika-dannyh--informaczionnoe-obespechenie-biznes-proczessov--informaczionnoe-obespechenie-avtomatizirovannyh-sistem--intellektualnye-informaczionnye-sistemy-i-tehnologii--czifrovaya-transformacziya-informaczionnyh-sistem--geoinformaczionnye-tehnologii-i-3-d-modelirovanie--upravlenie-bankovskoj-i-informaczionnoj-bezopasnostyu',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--graficheskij-dizajn-1--graficheskij-dizajner--dizajner-interera--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--razrabotka-i-dizajn-veb-prilozhenij--graficheskij-dizajn-1--graficheskij-dizajner--dizajner-interera--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo--razrabotka-i-dizajn-veb-prilozhenij--graficheskij-dizajn-1--graficheskij-dizajner--dizajner-interera--landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
        destination: '/programmy',
        permanent: true
      },
      {
        source:
          '/programmy--kadrovyj-menedzhment-1--menedzhment-v-neftegazovom-komplekse--menedzhment-v-obrazovanii--sportivnyj-menedzhment-1--menedzhment-v-zdravoohranenii--menedzhment-v-mashinostroenii--menedzhment-v-stroitelstve-1--menedzhment-v-energetike--finansovyj-menedzhment--antikrizisnyj-menedzhment--buhgalterskij-uchet-i-finansovyj-menedzhment-1--menedzhment-v-smi-i-reklame--menedzhment-v-turizme--menedzhment-gostinichnyh-i-restorannyh-predpriyatij--organizaczionnyj-menedzhment--proizvodstvennyj-menedzhment--strategicheskij-menedzhment--proektnyj-menedzhment--menedzhment-v-organizaczii--finansovyj-menedzhment-1--menedzhment-organizaczii-v-zdravoohranenii--hr-menedzher-s-nulya--product-menedzher--logisticheskij-menedzhment--menedzhment-v-neftegazovom-komplekse-1--menedzher-prodazham',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/menedzhment-organizaczii-v-zdravoohranii--kurs',
        destination: '/',
        permanent: true
      },
      {
        source: '/menedzhment-v-kibersporte-i-fidzhital-sporte--magistratura',
        destination: '/',
        permanent: true
      },
      { source: '/geshtalt-terapevt--kurs', destination: '/', permanent: true },
      {
        source: '/yuridicheskie-aspekty-upravlenii-v-sfere-zh-kh--kurs',
        destination: '/',
        permanent: true
      },
      {
        source: '/upravlenie-czifrovoj-transformacziej-biznesa--magistratura',
        destination: '/',
        permanent: true
      },
      {
        source: '/klinicheskij-psiholog--kurs',
        destination: '/',
        permanent: true
      },
      { source: '/biznes-analitik-1--kurs', destination: '/', permanent: true },
      {
        source: '/internet-marketolog--kurs',
        destination: '/',
        permanent: true
      },
      {
        source: '/menedzher-v-neftegazovom-komplekse--kurs',
        destination: '/',
        permanent: true
      },
      {
        source: '/soczialnyj-psiholog--kurs',
        destination: '/',
        permanent: true
      },
      {
        source: '/psihologicheskoe-konsultirovanie--kurs',
        destination: '/',
        permanent: true
      },
      { source: '/biznes-analitika', destination: '/', permanent: true },
      {
        source: '/yuridicheskie-aspekty-upravlenii-v-sfere-zh-kh-1--kurs',
        destination: '/',
        permanent: true
      },
      { source: '/kasmkam', destination: '/', permanent: true },
      { source: '/utmerror.xml', destination: '/', permanent: true },
      { source: '/Group_610.svg', destination: '/', permanent: true },
      {
        source: '/graficheskij-dizajnbakalavriat',
        destination: '/',
        permanent: true
      },
      { source: '/preview', destination: '/', permanent: true },
      { source: '/judment.xmll', destination: '/', permanent: true },
      {
        source: '/kommunikaczionnyj-dizajn--bakalavriat',
        destination: '/',
        permanent: true
      },
      {
        source: '/inzhiniring--bakalavriat',
        destination: '/',
        permanent: true
      },
      {
        source: '/komyuniti-menedzhment--bakalavriat',
        destination: '/',
        permanent: true
      },
      {
        source: '/tehnologicheskoe-predprinimatelstvo--bakalavriat',
        destination: '/',
        permanent: true
      },
      {
        source: '/mezhdunarodnaya-torgovlya--bakalavriat',
        destination: '/',
        permanent: true
      },
      {
        source: '/elektronnaya-kommercziya--bakalavriat',
        destination: '/',
        permanent: true
      },
      {
        source: '/psychological-counseling--bachelor',
        destination: '/',
        permanent: true
      },
      {
        source: '/graficheskij-dizajn1--bakalavriat',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/journal--null--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        destination:
          '/journal--dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        permanent: true
      },
      {
        source: '/dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        destination:
          '/journal--dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        permanent: true
      },
      {
        source:
          '/journal----konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        destination:
          '/journal--dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        permanent: true
      },
      {
        source: '/--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        destination:
          '/journal--dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        permanent: true
      },
      {
        source: '/null--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        destination:
          '/journal--dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        permanent: true
      },
      {
        source: '/journal----iskusstvennyj-intellekt-v-zhizni-cheloveka',
        destination:
          '/journal--informatika--iskusstvennyj-intellekt-v-zhizni-cheloveka',
        permanent: true
      },
      {
        source: '/informatika--iskusstvennyj-intellekt-v-zhizni-cheloveka',
        destination:
          '/journal--informatika--iskusstvennyj-intellekt-v-zhizni-cheloveka',
        permanent: true
      },
      {
        source: '/--iskusstvennyj-intellekt-v-zhizni-cheloveka',
        destination:
          '/journal--informatika--iskusstvennyj-intellekt-v-zhizni-cheloveka',
        permanent: true
      },
      {
        source: '/iskusstvennyj-intellekt-v-zhizni-cheloveka',
        destination:
          '/journal--informatika--iskusstvennyj-intellekt-v-zhizni-cheloveka',
        permanent: true
      },
      {
        source:
          '/journal----osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        destination:
          '/journal--pro-obrazovanie--osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        permanent: true
      },
      {
        source: '/osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        destination:
          '/journal--pro-obrazovanie--osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        permanent: true
      },
      {
        source:
          '/pro-obrazovanie--osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        destination:
          '/journal--pro-obrazovanie--osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        permanent: true
      },
      {
        source:
          '/journal--bakalavriat--osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        destination:
          '/journal--pro-obrazovanie--osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege',
        permanent: true
      },
      {
        source:
          '/journal--bakalavriat--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        destination:
          '/journal--pro-obrazovanie--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        permanent: true
      },
      {
        source:
          '/zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        destination:
          '/journal--pro-obrazovanie--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        permanent: true
      },
      {
        source:
          '/pro-obrazovanie--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        destination:
          '/journal--pro-obrazovanie--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        permanent: true
      },
      {
        source:
          '/--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        destination:
          '/journal--pro-obrazovanie--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        permanent: true
      },
      {
        source:
          '/top-10-samyh-legkih-yazykov-programmirovaniya-dlya-sozdaniya-veb-sajtov',
        destination:
          '/journal--informatika--top-10-samyh-legkih-yazykov-programmirovaniya-dlya-sozdaniya-veb-sajtov',
        permanent: true
      },
      {
        source:
          '/journal----top-10-samyh-legkih-yazykov-programmirovaniya-dlya-sozdaniya-veb-sajtov',
        destination:
          '/journal--informatika--top-10-samyh-legkih-yazykov-programmirovaniya-dlya-sozdaniya-veb-sajtov',
        permanent: true
      },
      {
        source:
          '/informatika--top-10-samyh-legkih-yazykov-programmirovaniya-dlya-sozdaniya-veb-sajtov',
        destination:
          '/journal--informatika--top-10-samyh-legkih-yazykov-programmirovaniya-dlya-sozdaniya-veb-sajtov',
        permanent: true
      },
      {
        source:
          '/journal--null--it-speczialnosti-samye-vostrebovannye-napravleniya',
        destination:
          '/journal--informatika--it-speczialnosti-samye-vostrebovannye-napravleniya',
        permanent: true
      },
      {
        source: '/--it-speczialnosti-samye-vostrebovannye-napravleniya',
        destination:
          '/journal--informatika--it-speczialnosti-samye-vostrebovannye-napravleniya',
        permanent: true
      },
      {
        source: '/it-speczialnosti-samye-vostrebovannye-napravleniya',
        destination:
          '/journal--informatika--it-speczialnosti-samye-vostrebovannye-napravleniya',
        permanent: true
      },
      {
        source: '/journal----ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        destination:
          '/journal--informatika--ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        permanent: true
      },
      {
        source: '/--ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        destination:
          '/journal--informatika--ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        permanent: true
      },
      {
        source: '/ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        destination:
          '/journal--informatika--ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        permanent: true
      },
      {
        source: '/pro-obrazovanie--kakuyu-professiyu-vybrat-posle-11-klassa',
        destination:
          '/journal--pro-obrazovanie--kakuyu-professiyu-vybrat-posle-11-klassa',
        permanent: true
      },
      {
        source: '/kakuyu-professiyu-vybrat-posle-11-klassa',
        destination:
          '/journal--pro-obrazovanie--kakuyu-professiyu-vybrat-posle-11-klassa',
        permanent: true
      },
      {
        source: '/journal----kakuyu-professiyu-vybrat-posle-11-klassa',
        destination:
          '/journal--pro-obrazovanie--kakuyu-professiyu-vybrat-posle-11-klassa',
        permanent: true
      },
      {
        source: '/pro-obrazovanie--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        destination:
          '/journal--pro-obrazovanie--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        permanent: true
      },
      {
        source:
          '/journal--bakalavriat--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        destination:
          '/journal--pro-obrazovanie--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        permanent: true
      },
      {
        source: '/journal----vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        destination:
          '/journal--pro-obrazovanie--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        permanent: true
      },
      {
        source: '/it-sfera-11-populyarnyh-professij',
        destination: '/journal--informatika--it-sfera-11-populyarnyh-professij',
        permanent: true
      },
      {
        source: '/journal----it-sfera-11-populyarnyh-professij',
        destination: '/journal--informatika--it-sfera-11-populyarnyh-professij',
        permanent: true
      },
      {
        source: '/informatika--it-sfera-11-populyarnyh-professij',
        destination: '/journal--informatika--it-sfera-11-populyarnyh-professij',
        permanent: true
      },
      {
        source:
          '/kakoj-yazyk-programmirovaniya-vybrat-novichku-vidy-yazykov-i-harakteristiki',
        destination:
          '/journal--informatika--kakoj-yazyk-programmirovaniya-vybrat-novichku-vidy-yazykov-i-harakteristiki',
        permanent: true
      },
      {
        source:
          '/informatika--kakoj-yazyk-programmirovaniya-vybrat-novichku-vidy-yazykov-i-harakteristiki',
        destination:
          '/journal--informatika--kakoj-yazyk-programmirovaniya-vybrat-novichku-vidy-yazykov-i-harakteristiki',
        permanent: true
      },
      {
        source:
          '/journal----kakoj-yazyk-programmirovaniya-vybrat-novichku-vidy-yazykov-i-harakteristiki',
        destination:
          '/journal--informatika--kakoj-yazyk-programmirovaniya-vybrat-novichku-vidy-yazykov-i-harakteristiki',
        permanent: true
      },
      {
        source:
          '/informatika--knigi-po-programmirovaniyu-s-nulya-prakticheskoe-rukovodstvo-dlya-nachinayushhih',
        destination:
          '/journal--informatika--knigi-po-programmirovaniyu-s-nulya-prakticheskoe-rukovodstvo-dlya-nachinayushhih',
        permanent: true
      },
      {
        source:
          '/knigi-po-programmirovaniyu-s-nulya-prakticheskoe-rukovodstvo-dlya-nachinayushhih',
        destination:
          '/journal--informatika--knigi-po-programmirovaniyu-s-nulya-prakticheskoe-rukovodstvo-dlya-nachinayushhih',
        permanent: true
      },
      {
        source:
          '/journal----knigi-po-programmirovaniyu-s-nulya-prakticheskoe-rukovodstvo-dlya-nachinayushhih',
        destination:
          '/journal--informatika--knigi-po-programmirovaniyu-s-nulya-prakticheskoe-rukovodstvo-dlya-nachinayushhih',
        permanent: true
      },
      {
        source:
          '/journal----postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        destination:
          '/journal--pro-obrazovanie--postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        permanent: true
      },
      {
        source:
          '/postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        destination:
          '/journal--pro-obrazovanie--postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        permanent: true
      },
      {
        source:
          '/pro-obrazovanie--postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        destination:
          '/journal--pro-obrazovanie--postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        permanent: true
      },
      {
        source:
          '/journal--bakalavriat--mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros',
        destination:
          '/journal--pro-obrazovanie--mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros',
        permanent: true
      },
      {
        source:
          '/journal----mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros',
        destination:
          '/journal--pro-obrazovanie--mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros',
        permanent: true
      },
      {
        source: '/mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros',
        destination:
          '/journal--pro-obrazovanie--mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros',
        permanent: true
      },
      {
        source:
          '/dizajn--testovoe-zadanie-dlya-dizajnerov-zachem-nuzhno-i-kak-ego-vypolnyat',
        destination:
          '/journal--dizajn--testovoe-zadanie-dlya-dizajnerov-zachem-nuzhno-i-kak-ego-vypolnyat',
        permanent: true
      },
      {
        source:
          '/testovoe-zadanie-dlya-dizajnerov-zachem-nuzhno-i-kak-ego-vypolnyat',
        destination:
          '/journal--dizajn--testovoe-zadanie-dlya-dizajnerov-zachem-nuzhno-i-kak-ego-vypolnyat',
        permanent: true
      },
      {
        source:
          '/journal----vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        destination:
          '/journal--informatika--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        permanent: true
      },
      {
        source:
          '/vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        destination:
          '/journal--informatika--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        permanent: true
      },
      {
        source:
          '/journal----vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        destination:
          '/journal--informatika--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        permanent: true
      },
      {
        source:
          '/informatika--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        destination:
          '/journal--informatika--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        permanent: true
      },
      {
        source: '/chto-takoe-finansy-peremeshhenie-deneg-eto-kratko',
        destination:
          '/journal--ekonomika--chto-takoe-finansy-peremeshhenie-deneg-eto-kratko',
        permanent: true
      },
      {
        source: '/ekonomika--chto-takoe-finansy-peremeshhenie-deneg-eto-kratko',
        destination:
          '/journal--ekonomika--chto-takoe-finansy-peremeshhenie-deneg-eto-kratko',
        permanent: true
      },
      {
        source:
          '/pravila-kompoziczii-sozdaem-pervoe-vpechatlenie-ot-produkta-cherez-dizajn',
        destination:
          '/journal--dizajn--pravila-kompoziczii-sozdaem-pervoe-vpechatlenie-ot-produkta-cherez-dizajn',
        permanent: true
      },
      {
        source:
          '/dizajn--pravila-kompoziczii-sozdaem-pervoe-vpechatlenie-ot-produkta-cherez-dizajn',
        destination:
          '/journal--dizajn--pravila-kompoziczii-sozdaem-pervoe-vpechatlenie-ot-produkta-cherez-dizajn',
        permanent: true
      },
      {
        source:
          '/informatika--plyusy-i-minusy-iskusstvennogo-intellekta-vo-vseh-sferah-zhizni-cheloveka',
        destination:
          '/journal--informatika--plyusy-i-minusy-iskusstvennogo-intellekta-vo-vseh-sferah-zhizni-cheloveka',
        permanent: true
      },
      {
        source:
          '/--plyusy-i-minusy-iskusstvennogo-intellekta-vo-vseh-sferah-zhizni-cheloveka',
        destination:
          '/journal--informatika--plyusy-i-minusy-iskusstvennogo-intellekta-vo-vseh-sferah-zhizni-cheloveka',
        permanent: true
      },
      {
        source:
          '/minimalnye-bally-po-ege-dlya-postupleniya-chto-delat-esli-ne-preodolel-porog-dlya-vuza',
        destination:
          '/journal--pro-obrazovanie--minimalnye-bally-po-ege-dlya-postupleniya-chto-delat-esli-ne-preodolel-porog-dlya-vuza',
        permanent: true
      },
      {
        source:
          '/journal----minimalnye-bally-po-ege-dlya-postupleniya-chto-delat-esli-ne-preodolel-porog-dlya-vuza',
        destination:
          '/journal--pro-obrazovanie--minimalnye-bally-po-ege-dlya-postupleniya-chto-delat-esli-ne-preodolel-porog-dlya-vuza',
        permanent: true
      },
      {
        source: '/pedagogicheskoe-issledovanie-kakie-zadachi-reshaet',
        destination:
          '/journal--pedagogicheskoe-obrazovanie--pedagogicheskoe-issledovanie-kakie-zadachi-reshaet',
        permanent: true
      },
      {
        source:
          '/pedagogicheskoe-obrazovanie--pedagogicheskoe-issledovanie-kakie-zadachi-reshaet',
        destination:
          '/journal--pedagogicheskoe-obrazovanie--pedagogicheskoe-issledovanie-kakie-zadachi-reshaet',
        permanent: true
      },
      {
        source: '/dizajn--kompyuternyj-dizajn-ponyatiya-i-osobennosti',
        destination:
          '/journal--dizajn--kompyuternyj-dizajn-ponyatiya-i-osobennosti',
        permanent: true
      },
      {
        source: '/kompyuternyj-dizajn-ponyatiya-i-osobennosti',
        destination:
          '/journal--dizajn--kompyuternyj-dizajn-ponyatiya-i-osobennosti',
        permanent: true
      },
      {
        source: '/dizajn--kompozicziya-v-dizajne-kak-sozdat-idealnyj-sajt',
        destination:
          '/journal--dizajn--kompozicziya-v-dizajne-kak-sozdat-idealnyj-sajt',
        permanent: true
      },
      {
        source: '/kompozicziya-v-dizajne-kak-sozdat-idealnyj-sajt',
        destination:
          '/journal--dizajn--kompozicziya-v-dizajne-kak-sozdat-idealnyj-sajt',
        permanent: true
      },
      {
        source:
          '/psihologiya--vidy-samooczenki-kak-povliyat-na-slozhivshiesya-predstavleniya-o-sebe',
        destination:
          '/journal--psihologiya--vidy-samooczenki-kak-povliyat-na-slozhivshiesya-predstavleniya-o-sebe',
        permanent: true
      },
      {
        source:
          '/vidy-samooczenki-kak-povliyat-na-slozhivshiesya-predstavleniya-o-sebe',
        destination:
          '/journal--psihologiya--vidy-samooczenki-kak-povliyat-na-slozhivshiesya-predstavleniya-o-sebe',
        permanent: true
      },
      {
        source:
          '/bakalavriat--zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit',
        destination: '/bakalavriat',
        permanent: true
      },
      {
        source:
          '/bakalavriat--mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros',
        destination: '/bakalavriat',
        permanent: true
      },
      {
        source:
          '/strategiya-v-menedzhmente-eto-osnova-uspeshnogo-razvitiya-kompanii',
        destination:
          '/journal--menedzhment--strategiya-v-menedzhmente-eto-osnova-uspeshnogo-razvitiya-kompanii',
        permanent: true
      },
      {
        source:
          '/menedzhment--strategiya-v-menedzhmente-eto-osnova-uspeshnogo-razvitiya-kompanii',
        destination:
          '/journal--menedzhment--strategiya-v-menedzhmente-eto-osnova-uspeshnogo-razvitiya-kompanii',
        permanent: true
      },
      {
        source:
          '/marketing--czel-marketingovogo-issledovaniya-osnova-uspeshnoj-strategii-prodvizheniya-na-rynke',
        destination:
          '/journal--marketing--czel-marketingovogo-issledovaniya-osnova-uspeshnoj-strategii-prodvizheniya-na-rynke',
        permanent: true
      },
      {
        source:
          '/czel-marketingovogo-issledovaniya-osnova-uspeshnoj-strategii-prodvizheniya-na-rynke',
        destination:
          '/journal--marketing--czel-marketingovogo-issledovaniya-osnova-uspeshnoj-strategii-prodvizheniya-na-rynke',
        permanent: true
      },
      {
        source:
          '/pro-obrazovanie--kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        destination:
          '/journal--pro-obrazovanie--kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        permanent: true
      },
      {
        source:
          '/journal----kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        destination:
          '/journal--pro-obrazovanie--kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        permanent: true
      },
      {
        source:
          '/psiholog-vostrebovannaya-professiya-2023-plyusy-i-minusy-speczialnosti-kuda-pojti-uchitsya',
        destination:
          '/journal--psihologiya--psiholog-vostrebovannaya-professiya-2023-plyusy-i-minusy-speczialnosti-kuda-pojti-uchitsya',
        permanent: true
      },
      {
        source:
          '/psihologiya--psiholog-vostrebovannaya-professiya-2023-plyusy-i-minusy-speczialnosti-kuda-pojti-uchitsya',
        destination:
          '/journal--psihologiya--psiholog-vostrebovannaya-professiya-2023-plyusy-i-minusy-speczialnosti-kuda-pojti-uchitsya',
        permanent: true
      },
      {
        source: '/finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source: '/ekonomika--finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source:
          '/knigi-po-programmirovaniyu-podborka-dlya-teh-kto-hochet-prokachat-svoi-navyki',
        destination:
          '/journal--informatika--knigi-po-programmirovaniyu-podborka-dlya-teh-kto-hochet-prokachat-svoi-navyki',
        permanent: true
      },
      {
        source:
          '/informatika--knigi-po-programmirovaniyu-podborka-dlya-teh-kto-hochet-prokachat-svoi-navyki',
        destination:
          '/journal--informatika--knigi-po-programmirovaniyu-podborka-dlya-teh-kto-hochet-prokachat-svoi-navyki',
        permanent: true
      },
      {
        source:
          '/journal----oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        destination:
          '/journal--informatika--oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        permanent: true
      },
      {
        source: '/oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        destination:
          '/journal--informatika--oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        permanent: true
      },
      {
        source:
          '/menedzhment--proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza',
        destination:
          '/journal--menedzhment--proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza',
        permanent: true
      },
      {
        source:
          '/marketingovoe-issledovanie-rynka-zachem-nuzhno-biznesu-i-kak-ego-provesti',
        destination:
          '/journal--marketing--marketingovoe-issledovanie-rynka-zachem-nuzhno-biznesu-i-kak-ego-provesti',
        permanent: true
      },
      {
        source:
          '/marketing--marketingovoe-issledovanie-rynka-zachem-nuzhno-biznesu-i-kak-ego-provesti',
        destination:
          '/journal--marketing--marketingovoe-issledovanie-rynka-zachem-nuzhno-biznesu-i-kak-ego-provesti',
        permanent: true
      },
      {
        source:
          '/informatika--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        destination:
          '/journal--informatika--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        permanent: true
      },
      {
        source:
          '/--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        destination:
          '/journal--informatika--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        permanent: true
      },
      {
        source: '/programma-marketingovogo-issledovaniya-osobennosti-i-czel',
        destination:
          '/journal--marketing--programma-marketingovogo-issledovaniya-osobennosti-i-czel',
        permanent: true
      },
      {
        source: '/journal----man-in-the-middle-chto-eto-takoe',
        destination: '/journal--informatika--man-in-the-middle-chto-eto-takoe',
        permanent: true
      },
      {
        source:
          '/marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa',
        destination:
          '/journal--marketing--marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa',
        permanent: true
      },
      {
        source: '/krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source:
          '/journal----obrazovanie-it-gde-poluchit-i-kak-vybrat-uchebnoe-zavedenie',
        destination:
          '/journal--informatika--obrazovanie-it-gde-poluchit-i-kak-vybrat-uchebnoe-zavedenie',
        permanent: true
      },
      {
        source:
          '/pro-obrazovanie--chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        destination:
          '/journal--pro-obrazovanie--chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        permanent: true
      },
      {
        source:
          '/komanda-proekta-rol-kazhdogo-chlena-v-uspeshnom-reshenii-zadach',
        destination:
          '/journal--menedzhment--komanda-proekta-rol-kazhdogo-chlena-v-uspeshnom-reshenii-zadach',
        permanent: true
      },
      {
        source:
          '/ekonomika--rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii',
        destination:
          '/journal--ekonomika--rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii',
        permanent: true
      },
      {
        source:
          '/graficheskij-dizajn-chto-eto-takoe-i-v-kakih-sferah-trebuetsya',
        destination:
          '/journal--dizajn--graficheskij-dizajn-chto-eto-takoe-i-v-kakih-sferah-trebuetsya',
        permanent: true
      },
      {
        source: '/knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii',
        destination:
          '/journal--ekonomika--knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii',
        permanent: true
      },
      {
        source:
          '/chto-takoe-sindrom-otlozhennoj-zhizni-ili-kak-nauchitsya-zhit-segodnyashnim-dnem',
        destination:
          '/journal--psihologiya--chto-takoe-sindrom-otlozhennoj-zhizni-ili-kak-nauchitsya-zhit-segodnyashnim-dnem',
        permanent: true
      },
      {
        source:
          '/adekvatnaya-samooczenka-luchshij-sposob-zhit-v-garmonii-s-soboj',
        destination:
          '/journal--psihologiya--adekvatnaya-samooczenka-luchshij-sposob-zhit-v-garmonii-s-soboj',
        permanent: true
      },
      {
        source:
          '/informatika--metody-iskusstvennogo-intellekta-osobennosti-kazhdogo-podhoda',
        destination:
          '/journal--informatika--metody-iskusstvennogo-intellekta-osobennosti-kazhdogo-podhoda',
        permanent: true
      },
      {
        source:
          '/6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny',
        destination:
          '/journal--dizajn--6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny',
        permanent: true
      },
      {
        source:
          '/yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka',
        destination:
          '/journal--yurisprudencziya--yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka',
        permanent: true
      },
      {
        source: '/ekonomist-menedzher-plyusy-i-minusy-professii',
        destination:
          '/journal--ekonomika--ekonomist-menedzher-plyusy-i-minusy-professii',
        permanent: true
      },
      {
        source: '/psihologiya--krizis-v-psihologii-eto-tonkosti-i-nyuansy',
        destination:
          '/journal--psihologiya--krizis-v-psihologii-eto-tonkosti-i-nyuansy',
        permanent: true
      },
      {
        source: '/samooczenka-kak-formiruetsya-i-na-chto-vliyaet',
        destination:
          '/journal--psihologiya--samooczenka-kak-formiruetsya-i-na-chto-vliyaet',
        permanent: true
      },
      {
        source:
          '/dizajn-konczepcziya-i-vizualnye-metafory-gde-nahodit-idei-i-kak-rabotat-nad-proektom',
        destination:
          '/journal--dizajn--dizajn-konczepcziya-i-vizualnye-metafory-gde-nahodit-idei-i-kak-rabotat-nad-proektom',
        permanent: true
      },
      {
        source: '/proektirovanie-zdanij-i-sooruzhenij--kurs/',
        destination: '/proektirovanie-zdanij-i-sooruzhenij--kurs',
        permanent: true
      },
      {
        source:
          '/osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej',
        destination:
          '/journal--dizajn--osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej',
        permanent: true
      },
      {
        source:
          '/ekonomicheskoe-obrazovanie-klyuch-k-uspeshnoj-karere-v-finansovoj-sfere',
        destination:
          '/journal--ekonomika--ekonomicheskoe-obrazovanie-klyuch-k-uspeshnoj-karere-v-finansovoj-sfere',
        permanent: true
      },
      {
        source: '/psihologicheskoe-konsultirovanie-2--kurs',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/operator-informaczionnyh-sistem-i-resursov--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/operator-informaczionnyh-sistem-i-resursov--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source: '/prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source: '/prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source: '/operator-informaczionnyh-sistem-i-resursov--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source: '/seksolog-v-psihologicheskom-konsultirovanii--kurs',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/operator-informaczionnyh-sistem-i-resursov--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/operator-informaczionnyh-sistem-i-resursov--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/operator-informaczionnyh-sistem-i-resursov--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source:
          '/operator-informaczionnyh-sistem-i-resursov--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--college',
        destination: '/',
        permanent: true
      },
      {
        source: '/stroitelstvo--bakalavriat',
        destination: '/bakalavriat--fakultet--stroitelstvo',
        permanent: true
      },
      {
        source: '/menedzhment--bakalavriat',
        destination: '/bakalavriat--fakultet--menedzhment',
        permanent: true
      },
      {
        source: '/sportivnyj-menedzhment-1--menedzhment--bakalavriat',
        destination: '/sportivnyj-menedzhment-1--bakalavriat',
        permanent: true
      },
      {
        source: '/dizajn--bakalavriat',
        destination: '/bakalavriat--fakultet--dizajn',
        permanent: true
      },
      {
        source: '/menedzhment-igrovoj-industrii-i-kibersporta--bakalavriat',
        destination: '/bakalavriat--fakultet--menedzhment',
        permanent: true
      },
      {
        source: '/multimedijnaya-zhurnalistika--bakalavriat',
        destination: '/bakalavriat',
        permanent: true
      },
      {
        source:
          '/promyshlennoe-i-grazhdanskoe-stroitelstvo--stroitelstvo--bakalavriat',
        destination: '/promyshlennoe-i-grazhdanskoe-stroitelstvo--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source: '/psihologicheskoe-konsultirovanie-1--psihologiya--bakalavriat',
        destination: '/psihologicheskoe-konsultirovanie-1--bakalavriat',
        permanent: true
      },
      {
        source: '/menedzhment-v-smi-i-reklame--menedzhment--bakalavriat',
        destination: '/menedzhment-v-smi-i-reklame--bakalavriat',
        permanent: true
      },
      {
        source:
          '/hudozhnik-animaczii-i-kompyuternoj-grafiki--dizajn--dizajn--dizajn--bakalavriat',
        destination: '/hudozhnik-animaczii-i-kompyuternoj-grafiki--bakalavriat',
        permanent: true
      },
      {
        source: '/gosudarstvennaya-sluzhba--bakalavriat',
        destination: '/bakalavriat',
        permanent: true
      },
      {
        source:
          '/razrabotka-kompyuternyh-igr-i-multimedijnyh-prilozhenij--bakalavriat',
        destination: '/bakalavriat',
        permanent: true
      },
      {
        source: '/graficheskij-dizajner-1--bakalavriat',
        destination: '/graficheskij-dizajn-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--menedzhment--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source:
          '/menedzhment-v-neftegazovom-komplekse--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--menedzhment--bakalavriat',
        destination: '/menedzhment-v-neftegazovom-komplekse--bakalavriat',
        permanent: true
      },
      {
        source:
          '/krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source:
          '/krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source:
          '/krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--fakultet--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source:
          '/krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source:
          '/krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source:
          '/finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source:
          '/finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source:
          '/finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source:
          '/finansovye-professii-vostrebovannye-v-mire--fakultet--finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source:
          '/finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source:
          '/finansovye-professii-vostrebovannye-v-mire--finansovye-professii-vostrebovannye-v-mire',
        destination:
          '/journal--ekonomika--finansovye-professii-vostrebovannye-v-mire',
        permanent: true
      },
      {
        source:
          '/kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        destination:
          '/journal--informatika--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        permanent: true
      },
      {
        source:
          '/journal----kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        destination:
          '/journal--informatika--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        permanent: true
      },
      {
        source:
          '/kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo--fakultet--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        destination:
          '/journal--informatika--kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo',
        permanent: true
      },
      {
        source: '/--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        destination:
          '/journal--informatika--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        permanent: true
      },
      {
        source: '/vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        destination:
          '/journal--informatika--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        permanent: true
      },
      {
        source:
          '/vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie--fakultet--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        destination:
          '/journal--informatika--vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie',
        permanent: true
      },
      {
        source: '/kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov',
        destination:
          '/journal--prikladnaya-informatika--kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov',
        permanent: true
      },
      {
        source:
          '/kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov--fakultet--kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov',
        destination:
          '/journal--prikladnaya-informatika--kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov',
        permanent: true
      },
      {
        source:
          '/prikladnaya-informatika--kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov',
        destination:
          '/journal--prikladnaya-informatika--kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov',
        permanent: true
      },
      {
        source:
          '/chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov--fakultet--chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        destination:
          '/journal--pro-obrazovanie--chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        permanent: true
      },
      {
        source: '/chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        destination:
          '/journal--pro-obrazovanie--chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        permanent: true
      },
      {
        source:
          '/journal----chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        destination:
          '/journal--pro-obrazovanie--chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov',
        permanent: true
      },
      {
        source:
          '/dizajn--stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah',
        destination:
          '/journal--dizajn--stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah',
        permanent: true
      },
      {
        source:
          '/stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah',
        destination:
          '/journal--dizajn--stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah',
        permanent: true
      },
      {
        source:
          '/stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah--fakultet--stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah',
        destination:
          '/journal--dizajn--stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah',
        permanent: true
      },
      {
        source:
          '/finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu',
        destination:
          '/journal--ekonomika--finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu',
        permanent: true
      },
      {
        source:
          '/finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu--fakultet--finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu',
        destination:
          '/journal--ekonomika--finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu',
        permanent: true
      },
      {
        source:
          '/ekonomika--finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu',
        destination:
          '/journal--ekonomika--finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu',
        permanent: true
      },
      {
        source:
          '/ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami--fakultet--ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami',
        destination:
          '/journal--ekonomika--ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami',
        permanent: true
      },
      {
        source:
          '/ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami',
        destination:
          '/journal--ekonomika--ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami',
        permanent: true
      },
      {
        source:
          '/ekonomika--ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami',
        destination:
          '/journal--ekonomika--ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami',
        permanent: true
      },
      {
        source:
          '/marketingovoe-issledovanie-vidy-czeli-i-zadachi--fakultet--marketingovoe-issledovanie-vidy-czeli-i-zadachi',
        destination:
          '/journal--marketing--marketingovoe-issledovanie-vidy-czeli-i-zadachi',
        permanent: true
      },
      {
        source: '/marketingovoe-issledovanie-vidy-czeli-i-zadachi',
        destination:
          '/journal--marketing--marketingovoe-issledovanie-vidy-czeli-i-zadachi',
        permanent: true
      },
      {
        source: '/marketing--marketingovoe-issledovanie-vidy-czeli-i-zadachi',
        destination:
          '/journal--marketing--marketingovoe-issledovanie-vidy-czeli-i-zadachi',
        permanent: true
      },
      {
        source:
          '/hudozhnik-animaczii-i-kompyuternoj-grafiki--dizajn--dizajn--bakalavriat',
        destination: '/hudozhnik-animaczii-i-kompyuternoj-grafiki--bakalavriat',
        permanent: true
      },
      {
        source:
          '/hudozhnik-animaczii-i-kompyuternoj-grafiki--dizajn--dizajn--dizajn--dizajn--dizajn--bakalavriat',
        destination: '/hudozhnik-animaczii-i-kompyuternoj-grafiki--bakalavriat',
        permanent: true
      },
      {
        source:
          '/hudozhnik-animaczii-i-kompyuternoj-grafiki--dizajn--bakalavriat',
        destination: '/hudozhnik-animaczii-i-kompyuternoj-grafiki--bakalavriat',
        permanent: true
      },
      {
        source: '/mezhdunarodnye-finansy--ekonomika--ekonomika--bakalavriat',
        destination: '/mezhdunarodnye-finansy--bakalavriat',
        permanent: true
      },
      {
        source: '/mezhdunarodnye-finansy--ekonomika--bakalavriat',
        destination: '/mezhdunarodnye-finansy--bakalavriat',
        permanent: true
      },
      {
        source:
          '/mezhdunarodnye-finansy--ekonomika--ekonomika--ekonomika--bakalavriat',
        destination: '/mezhdunarodnye-finansy--bakalavriat',
        permanent: true
      },
      {
        source: '/prikladnaya-informatika--man-in-the-middle-chto-eto-takoe',
        destination: '/journal--informatika--man-in-the-middle-chto-eto-takoe',
        permanent: true
      },
      {
        source:
          '/man-in-the-middle-chto-eto-takoe--fakultet--man-in-the-middle-chto-eto-takoe',
        destination: '/journal--informatika--man-in-the-middle-chto-eto-takoe',
        permanent: true
      },
      {
        source: '/informatika--man-in-the-middle-chto-eto-takoe',
        destination: '/journal--informatika--man-in-the-middle-chto-eto-takoe',
        permanent: true
      },
      {
        source:
          '/kouching-i-psihologicheskoe-konsultirovanie--psihologiya--magistratura',
        destination:
          '/kouching-i-psihologicheskoe-konsultirovanie--magistratura',
        permanent: true
      },
      {
        source: '/kouching-i-psihologicheskoe-konsultirovanie--kurs',
        destination:
          '/kouching-i-psihologicheskoe-konsultirovanie--magistratura',
        permanent: true
      },
      {
        source:
          '/razrabotka-multimedijnyh-prilozhenij--prikladnaya-informatika--bakalavriat',
        destination: '/razrabotka-multimedijnyh-prilozhenij--bakalavriat',
        permanent: true
      },
      {
        source:
          '/razrabotka-multimedijnyh-prilozhenij--prikladnaya-informatika--prikladnaya-informatika--bakalavriat',
        destination: '/razrabotka-multimedijnyh-prilozhenij--bakalavriat',
        permanent: true
      },
      {
        source:
          '/osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej--fakultet--osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej',
        destination:
          '/journal--dizajn--osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej',
        permanent: true
      },
      {
        source:
          '/dizajn--osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej',
        destination:
          '/journal--dizajn--osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej',
        permanent: true
      },
      {
        source:
          '/dizajn--6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny',
        destination:
          '/journal--dizajn--6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny',
        permanent: true
      },
      {
        source:
          '/6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny--fakultet--6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny',
        destination:
          '/journal--dizajn--6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny',
        permanent: true
      },
      {
        source:
          '/samooczenka-kak-formiruetsya-i-na-chto-vliyaet--fakultet--samooczenka-kak-formiruetsya-i-na-chto-vliyaet',
        destination:
          '/journal--psihologiya--samooczenka-kak-formiruetsya-i-na-chto-vliyaet',
        permanent: true
      },
      {
        source: '/psihologiya--samooczenka-kak-formiruetsya-i-na-chto-vliyaet',
        destination:
          '/journal--psihologiya--samooczenka-kak-formiruetsya-i-na-chto-vliyaet',
        permanent: true
      },
      {
        source:
          '/vysshee-obrazovanie-bez-ege-eto-vozmozhno--fakultet--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        destination:
          '/journal--pro-obrazovanie--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        permanent: true
      },
      {
        source: '/vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        destination:
          '/journal--pro-obrazovanie--vysshee-obrazovanie-bez-ege-eto-vozmozhno',
        permanent: true
      },
      {
        source:
          '/journal----metody-iskusstvennogo-intellekta-osobennosti-kazhdogo-podhoda',
        destination:
          '/journal--informatika--metody-iskusstvennogo-intellekta-osobennosti-kazhdogo-podhoda',
        permanent: true
      },
      {
        source:
          '/metody-iskusstvennogo-intellekta-osobennosti-kazhdogo-podhoda',
        destination:
          '/journal--informatika--metody-iskusstvennogo-intellekta-osobennosti-kazhdogo-podhoda',
        permanent: true
      },
      {
        source:
          '/knigi-po-razvitiyu-emoczionalnogo-intellekta-instrukczii-kak-upravlyat-emocziyami--fakultet--knigi-po-razvitiyu-emoczionalnogo-intellekta-instrukczii-kak-upravlyat-emocziyami',
        destination:
          '/journal--pedagogicheskoe-obrazovanie--knigi-po-razvitiyu-emoczionalnogo-intellekta-instrukczii-kak-upravlyat-emocziyami',
        permanent: true
      },
      {
        source:
          '/knigi-po-razvitiyu-emoczionalnogo-intellekta-instrukczii-kak-upravlyat-emocziyami',
        destination:
          '/journal--pedagogicheskoe-obrazovanie--knigi-po-razvitiyu-emoczionalnogo-intellekta-instrukczii-kak-upravlyat-emocziyami',
        permanent: true
      },
      {
        source: '/logistika-1--menedzhment--bakalavriat',
        destination: '/logistika-1--bakalavriat',
        permanent: true
      },
      {
        source: '/logistika-1--menedzhment--menedzhment--bakalavriat',
        destination: '/logistika-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/czifrovaya-ekonomika-opredelenie-ponyatiya-osobennosti-i-kak-ona-vliyaet-na-biznes--fakultet--czifrovaya-ekonomika-opredelenie-ponyatiya-osobennosti-i-kak-ona-vliyaet-na-biznes',
        destination:
          '/journal--ekonomika--czifrovaya-ekonomika-opredelenie-ponyatiya-osobennosti-i-kak-ona-vliyaet-na-biznes',
        permanent: true
      },
      {
        source:
          '/czifrovaya-ekonomika-opredelenie-ponyatiya-osobennosti-i-kak-ona-vliyaet-na-biznes',
        destination:
          '/journal--ekonomika--czifrovaya-ekonomika-opredelenie-ponyatiya-osobennosti-i-kak-ona-vliyaet-na-biznes',
        permanent: true
      },
      {
        source:
          '/marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa--fakultet--marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa',
        destination:
          '/journal--marketing--marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa',
        permanent: true
      },
      {
        source:
          '/marketing--marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa',
        destination:
          '/journal--marketing--marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa',
        permanent: true
      },
      {
        source:
          '/buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva--fakultet--buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva',
        destination:
          '/journal--ekonomika--buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva',
        permanent: true
      },
      {
        source:
          '/buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva',
        destination:
          '/journal--ekonomika--buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva',
        permanent: true
      },
      {
        source:
          '/boremsya-s-emoczionalnym-vygoraniem-kak-obnaruzhit-i-pobedit-sostoyanie--fakultet--boremsya-s-emoczionalnym-vygoraniem-kak-obnaruzhit-i-pobedit-sostoyanie',
        destination:
          '/journal--psihologiya--boremsya-s-emoczionalnym-vygoraniem-kak-obnaruzhit-i-pobedit-sostoyanie',
        permanent: true
      },
      {
        source:
          '/psihologiya--boremsya-s-emoczionalnym-vygoraniem-kak-obnaruzhit-i-pobedit-sostoyanie',
        destination:
          '/journal--psihologiya--boremsya-s-emoczionalnym-vygoraniem-kak-obnaruzhit-i-pobedit-sostoyanie',
        permanent: true
      },
      {
        source:
          '/marketing--cjm-ili-customer-journey-map-kak-sostavit-i-oczenit-effektivnost',
        destination:
          '/journal--marketing--cjm-ili-customer-journey-map-kak-sostavit-i-oczenit-effektivnost',
        permanent: true
      },
      {
        source:
          '/cjm-ili-customer-journey-map-kak-sostavit-i-oczenit-effektivnost--fakultet--cjm-ili-customer-journey-map-kak-sostavit-i-oczenit-effektivnost',
        destination:
          '/journal--marketing--cjm-ili-customer-journey-map-kak-sostavit-i-oczenit-effektivnost',
        permanent: true
      },
      {
        source:
          '/yurisprudencziya--yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka',
        destination:
          '/journal--yurisprudencziya--yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka',
        permanent: true
      },
      {
        source:
          '/yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka--fakultet--yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka',
        destination:
          '/journal--yurisprudencziya--yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka',
        permanent: true
      },
      {
        source: '/konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        destination:
          '/journal--dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        permanent: true
      },
      {
        source:
          '/konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta--fakultet--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        destination:
          '/journal--dizajn--konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta',
        permanent: true
      },
      {
        source: '/psihologiya-1--psihologiya--bakalavriat',
        destination: '/psihologiya-1--bakalavriat',
        permanent: true
      },
      {
        source: '/psihologiya-1--psihologiya--psihologiya--bakalavriat',
        destination: '/psihologiya-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/informaczionnoe-obespechenie-avtomatizirovannyh-sistem--prikladnaya-informatika--prikladnaya-informatika--prikladnaya-informatika--bakalavriat',
        destination:
          '/informaczionnoe-obespechenie-avtomatizirovannyh-sistem--bakalavriat',
        permanent: true
      },
      {
        source:
          '/informaczionnoe-obespechenie-avtomatizirovannyh-sistem--prikladnaya-informatika--prikladnaya-informatika--bakalavriat',
        destination:
          '/informaczionnoe-obespechenie-avtomatizirovannyh-sistem--bakalavriat',
        permanent: true
      },
      {
        source: '/krizis-v-psihologii-eto-tonkosti-i-nyuansy',
        destination:
          '/journal--psihologiya--krizis-v-psihologii-eto-tonkosti-i-nyuansy',
        permanent: true
      },
      {
        source:
          '/krizis-v-psihologii-eto-tonkosti-i-nyuansy--fakultet--krizis-v-psihologii-eto-tonkosti-i-nyuansy',
        destination:
          '/journal--psihologiya--krizis-v-psihologii-eto-tonkosti-i-nyuansy',
        permanent: true
      },
      {
        source:
          '/sportivnyj-menedzhment-1--menedzhment--menedzhment--bakalavriat',
        destination: '/sportivnyj-menedzhment-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/sportivnyj-menedzhment-1--menedzhment--menedzhment--menedzhment--bakalavriat',
        destination: '/sportivnyj-menedzhment-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/journal----prohodnoj-ball-ege-chem-on-otlichaetsya-ot-minimalnogo',
        destination:
          '/journal--pro-obrazovanie--prohodnoj-ball-ege-chem-on-otlichaetsya-ot-minimalnogo',
        permanent: true
      },
      {
        source:
          '/prohodnoj-ball-ege-chem-on-otlichaetsya-ot-minimalnogo--fakultet--prohodnoj-ball-ege-chem-on-otlichaetsya-ot-minimalnogo',
        destination:
          '/journal--pro-obrazovanie--prohodnoj-ball-ege-chem-on-otlichaetsya-ot-minimalnogo',
        permanent: true
      },
      {
        source: '/programmy--stroitelstvo',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/programmy--prikladnaya-informatika',
        destination: '/programmy',
        permanent: true
      },
      {
        source: '/kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        destination:
          '/journal--pro-obrazovanie--kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        permanent: true
      },
      {
        source:
          '/kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa--fakultet--kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        destination:
          '/journal--pro-obrazovanie--kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa',
        permanent: true
      },
      {
        source:
          '/rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat--fakultet--rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat',
        destination:
          '/journal--informatika--rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat',
        permanent: true
      },
      {
        source:
          '/informatika--rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat',
        destination:
          '/journal--informatika--rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat',
        permanent: true
      },
      {
        source:
          '/gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku--fakultet--gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku',
        destination:
          '/journal--ekonomika--gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku',
        permanent: true
      },
      {
        source:
          '/ekonomika--gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku',
        destination:
          '/journal--ekonomika--gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku',
        permanent: true
      },
      {
        source: '/klinicheskaya-psihologiya-1--psihologiya--bakalavriat',
        destination: '/klinicheskaya-psihologiya-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/klinicheskaya-psihologiya-1--psihologiya--psihologiya--bakalavriat',
        destination: '/klinicheskaya-psihologiya-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/psihologiya--chto-takoe-sindrom-otlozhennoj-zhizni-ili-kak-nauchitsya-zhit-segodnyashnim-dnem',
        destination:
          '/journal--psihologiya--chto-takoe-sindrom-otlozhennoj-zhizni-ili-kak-nauchitsya-zhit-segodnyashnim-dnem',
        permanent: true
      },
      {
        source: '/journal--bakalavriat',
        destination: '/journal',
        permanent: true
      },
      {
        source: '/journal--null',
        destination: '/journal',
        permanent: true
      },
      {
        source: '/yuridicheskaya-psihologiya--psihologiya--bakalavriat',
        destination: '/yuridicheskaya-psihologiya--bakalavriat',
        permanent: true
      },
      {
        source: '/organizaczionnaya-psihologiya-1--kurs',
        destination: '/organizaczionnaya-psihologiya-1--magistratura',
        permanent: true
      },
      {
        source: '/journal--',
        destination: '/journal',
        permanent: true
      },
      {
        source:
          '/gorodskoe-stroitelstvo-i-hozyajstvo--stroitelstvo--bakalavriat',
        destination: '/gorodskoe-stroitelstvo-i-hozyajstvo--bakalavriat',
        permanent: true
      },
      {
        source:
          '/ekonomist-analitik-obyazannosti-i-navyki-dlya-uspeshnoj-karery--fakultet--ekonomist-analitik-obyazannosti-i-navyki-dlya-uspeshnoj-karery',
        destination:
          '/journal--ekonomika--ekonomist-analitik-obyazannosti-i-navyki-dlya-uspeshnoj-karery',
        permanent: true
      },
      {
        source:
          '/ekonomika--ekonomicheskoe-obrazovanie-klyuch-k-uspeshnoj-karere-v-finansovoj-sfere',
        destination:
          '/journal--ekonomika--ekonomicheskoe-obrazovanie-klyuch-k-uspeshnoj-karere-v-finansovoj-sfere',
        permanent: true
      },
      {
        source:
          '/informaczionnoe-obespechenie-biznes-proczessov--prikladnaya-informatika--bakalavriat',
        destination:
          '/informaczionnoe-obespechenie-biznes-proczessov--bakalavriat',
        permanent: true
      },
      {
        source: '/rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii',
        destination:
          '/journal--ekonomika--rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii',
        permanent: true
      },
      {
        source: '/nalogi-i-nalogooblozhenie-1--ekonomika--bakalavriat',
        destination: '/nalogi-i-nalogooblozhenie-1--bakalavriat',
        permanent: true
      },
      {
        source:
          '/proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza',
        destination:
          '/journal--menedzhment--proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza',
        permanent: true
      },
      {
        source:
          '/korrekczionnaya-psihologiya-s-osnovami-logopedii--psihologiya--bakalavriat',
        destination:
          '/korrekczionnaya-psihologiya-s-osnovami-logopedii--bakalavriat',
        permanent: true
      },
      {
        source:
          '/informatika--it-speczialnosti-samye-vostrebovannye-napravleniya',
        destination:
          '/journal--informatika--it-speczialnosti-samye-vostrebovannye-napravleniya',
        permanent: true
      },
      {
        source:
          '/upravlenie-gosudarstvennym-i-municzipalnym-sektorom--menedzhment--bakalavriat',
        destination:
          '/upravlenie-gosudarstvennym-i-municzipalnym-sektorom--bakalavriat',
        permanent: true
      },
      {
        source:
          '/informatika--oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        destination:
          '/journal--informatika--oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        permanent: true
      },
      {
        source: '/menedzhment-v-zdravoohranenii--menedzhment--bakalavriat',
        destination: '/menedzhment-v-zdravoohranenii--bakalavriat',
        permanent: true
      },
      {
        source: '/menedzhment-v-stroitelstve-1--menedzhment--bakalavriat',
        destination: '/menedzhment-v-stroitelstve-1--bakalavriat',
        permanent: true
      },
      {
        source: '/mirovaya-ekonomika--ekonomika--bakalavriat',
        destination: '/mirovaya-ekonomika--bakalavriat',
        permanent: true
      },
      {
        source:
          '/strategiya-razvitiya-kompanii-kak-sostavit-effektivnyj-plan-dlya-biznesa',
        destination:
          '/journal--menedzhment--strategiya-razvitiya-kompanii-kak-sostavit-effektivnyj-plan-dlya-biznesa',
        permanent: true
      },
      {
        source:
          '/knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii--fakultet--knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii',
        destination:
          '/journal--ekonomika--knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii',
        permanent: true
      },
      {
        source: '/chertezhnik-konstruktor--stroitelstvo--college',
        destination: '/chertezhnik-konstruktor--college',
        permanent: true
      },
      {
        source: '/psihologiya--fakultet--magistratura',
        destination: '/psihologiya--kurs',
        permanent: true
      },
      {
        source:
          '/marketing--princzipy-marketingovyh-issledovanij-sut-ih-provedeniya',
        destination:
          '/journal--marketing--princzipy-marketingovyh-issledovanij-sut-ih-provedeniya',
        permanent: true
      },
      {
        source:
          '/journal----plyusy-i-minusy-iskusstvennogo-intellekta-vo-vseh-sferah-zhizni-cheloveka',
        destination:
          '/journal--informatika--plyusy-i-minusy-iskusstvennogo-intellekta-vo-vseh-sferah-zhizni-cheloveka',
        permanent: true
      },
      {
        source:
          '/emoczionalnyj-intellekt-eto-vazhno-dlya-razvitiya-lyudej-i-otnoshenij--fakultet--emoczionalnyj-intellekt-eto-vazhno-dlya-razvitiya-lyudej-i-otnoshenij',
        destination:
          '/journal--pedagogicheskoe-obrazovanie--emoczionalnyj-intellekt-eto-vazhno-dlya-razvitiya-lyudej-i-otnoshenij',
        permanent: true
      },
      {
        source:
          '/journal----knigi-po-programmirovaniyu-podborka-dlya-teh-kto-hochet-prokachat-svoi-navyki',
        destination:
          '/journal--informatika--knigi-po-programmirovaniyu-podborka-dlya-teh-kto-hochet-prokachat-svoi-navyki',
        permanent: true
      },
      {
        source: '/upravlenie-malym-biznesom--menedzhment--bakalavriat',
        destination: '/upravlenie-malym-biznesom--bakalavriat',
        permanent: true
      },
      {
        source: '/bakalavriat--menedzhment',
        destination: '/bakalavriat--fakultet--menedzhment',
        permanent: true
      },
      {
        source:
          '/psihologiya--chto-takoe-sindrom-vygoraniya-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--chto-takoe-sindrom-vygoraniya-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source: '/ekonomika--ekonomist-menedzher-plyusy-i-minusy-professii',
        destination:
          '/journal--ekonomika--ekonomist-menedzher-plyusy-i-minusy-professii',
        permanent: true
      },
      {
        source: '/menedzhment-v-obrazovanii--menedzhment--bakalavriat',
        destination: '/menedzhment-v-obrazovanii--bakalavriat',
        permanent: true
      },
      {
        source:
          '/journal--bakalavriat--postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        destination:
          '/journal--pro-obrazovanie--postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2',
        permanent: true
      },
      {
        source:
          '/dizajn--dizajn-konczepcziya-i-vizualnye-metafory-gde-nahodit-idei-i-kak-rabotat-nad-proektom',
        destination:
          '/journal--dizajn--dizajn-konczepcziya-i-vizualnye-metafory-gde-nahodit-idei-i-kak-rabotat-nad-proektom',
        permanent: true
      },
      {
        source:
          '/vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy--fakultet--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        destination:
          '/journal--informatika--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        permanent: true
      },
      {
        source:
          '/--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        destination:
          '/journal--informatika--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        permanent: true
      },
      {
        source:
          '/informatika--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        destination:
          '/journal--informatika--vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy',
        permanent: true
      },
      {
        source:
          '/psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source:
          '/krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        destination:
          '/journal--psihologiya--krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya',
        permanent: true
      },
      {
        source: '/pedagogicheskoe-obrazovanie--bakalavriat',
        destination: '/bakalavriat--fakultet--pedagogicheskoe-obrazovanie',
        permanent: true
      },
      {
        source:
          '/fizicheskaya-kultura--pedagogicheskoe-obrazovanie--bakalavriat',
        destination: '/fizicheskaya-kultura--bakalavriat',
        permanent: true
      },
      {
        source: '/dizajn--dizajn--bakalavriat',
        destination: '/bakalavriat--fakultet--dizajn',
        permanent: true
      },
      {
        source:
          '/pro-obrazovanie--minimalnye-bally-po-ege-dlya-postupleniya-chto-delat-esli-ne-preodolel-porog-dlya-vuza',
        destination:
          '/journal--pro-obrazovanie--minimalnye-bally-po-ege-dlya-postupleniya-chto-delat-esli-ne-preodolel-porog-dlya-vuza',
        permanent: true
      },
      {
        source:
          '/ekonomika--knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii',
        destination:
          '/journal--ekonomika--knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii',
        permanent: true
      },
      {
        source:
          '/psihologiya--adekvatnaya-samooczenka-luchshij-sposob-zhit-v-garmonii-s-soboj',
        destination:
          '/journal--psihologiya--adekvatnaya-samooczenka-luchshij-sposob-zhit-v-garmonii-s-soboj',
        permanent: true
      },
      {
        source: '/marketing--marketingovyj-analiz-vidy-i-etapy-issledovaniya',
        destination:
          '/journal--marketing--marketingovyj-analiz-vidy-i-etapy-issledovaniya',
        permanent: true
      },
      {
        source:
          '/ekonomika--buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva',
        destination:
          '/journal--ekonomika--buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva',
        permanent: true
      },
      {
        source:
          '/ekonomist-menedzher-plyusy-i-minusy-professii--fakultet--ekonomist-menedzher-plyusy-i-minusy-professii',
        destination:
          '/journal--ekonomika--ekonomist-menedzher-plyusy-i-minusy-professii',
        permanent: true
      },
      {
        source:
          '/informatika--ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        destination:
          '/journal--informatika--ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya',
        permanent: true
      },
      {
        source:
          '/oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva--fakultet--oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        destination:
          '/journal--informatika--oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva',
        permanent: true
      },
      {
        source:
          '/rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii--fakultet--rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii',
        destination:
          '/journal--ekonomika--rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii',
        permanent: true
      },
      {
        source:
          '/proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza--fakultet--proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza',
        destination:
          '/journal--menedzhment--proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza',
        permanent: true
      },
      {
        source:
          '/journal----rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat',
        destination:
          '/journal--informatika--rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat',
        permanent: true
      },
      {
        source:
          '/informaczionnoe-obespechenie-avtomatizirovannyh-sistem--prikladnaya-informatika--bakalavriat',
        destination:
          '/informaczionnoe-obespechenie-avtomatizirovannyh-sistem--bakalavriat',
        permanent: true
      },
      {
        source: '/bankovskoe-delo--ekonomika--bakalavriat',
        destination: '/bankovskoe-delo--bakalavriat',
        permanent: true
      },
      {
        source:
          '/dizajn--graficheskij-dizajn-chto-eto-takoe-i-v-kakih-sferah-trebuetsya',
        destination:
          '/journal--dizajn--graficheskij-dizajn-chto-eto-takoe-i-v-kakih-sferah-trebuetsya',
        permanent: true
      },
      {
        source:
          '/gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku',
        destination:
          '/journal--ekonomika--gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku',
        permanent: true
      },
      {
        source: '/journal--yurisprudencziya--journal',
        destination:
          '/journal--yurisprudencziya--ponyatie-trudovogo-dogovora-osobennosti-zaklyucheniya-i-rastorzheniya',
        permanent: true
      }
      // {
      //   source: '/:locale/bachelor',
      //   destination: '/en/bachelor',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/bakalavriat-1',
      //   destination: '/kk/bakalavriat-1',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/bakalavr',
      //   destination: '/uz/bakalavr',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/magistr-de-geji',
      //   destination: '/kk/magistr-de-geji',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/master',
      //   destination: '/en/master',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/magistr-diplomi',
      //   destination: '/uz/magistr-diplomi',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/kurslar',
      //   destination: '/uz/kurslar',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/kurstar',
      //   destination: '/kk/kurstar',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/courses',
      //   destination: '/en/courses',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/programlar',
      //   destination: '/uz/programlar',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/programs',
      //   destination: '/en/programs',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/mba-1',
      //   destination: '/en/mba-1',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/mba-2',
      //   destination: '/kk/mba-2',
      //   permanent: false,
      //   locale: false
      // },
      // {
      //   source: '/:locale/mba-3',
      //   destination: '/uz/mba-3',
      //   permanent: false,
      //   locale: false
      // }
    ]
  },
  async headers() {
    return [
      {
        source: '/:path*',
        headers: [
          ...createSecureHeaders({
            forceHTTPSRedirect: dev
              ? false
              : [
                  true,
                  {
                    maxAge: 60 * 60 * 24 * 365 * 2
                    // includeSubDomains: true
                  }
                ],
            referrerPolicy: 'no-referrer-when-downgrade',
            acceptEncoding: 'gzip',
            frameGuard: false
          }),
          {
            key: 'Vary',
            value: 'Accept-Encoding'
          }
        ]
      }
    ]
  }
}

export default nextConfig

// // * this kind of way to do this (.mjx) is made for potential future use
// const createConfig = async (phase, { defaultConfig }) => {
//   const prod = process.env.NODE_ENV === 'production'
//   const dev = process.env.NODE_ENV === 'development'

//   const nextConfig = {
//     reactStrictMode: true, // * Recommended for the `pages` directory, default in `app`.
//     poweredByHeader: false, // * Remove the `X-Powered-By` header.
//     swcMinify: true,
//     compiler: {
//       styledComponents: true,
//       removeConsole: prod
//     },
//     i18n: {
//       locales: ['en', 'ru', 'uz', 'kk'],
//       defaultLocale: 'ru',
//       localeDetection: !dev
//     },
//     images: {
//       // loader: 'cloudinary',
//       // path: 'https://res.cloudinary.com/mitu-institute/',
//       domains: ['res.cloudinary.com']
//     },
//     experimental: {
//       // * Required:
//       // appDir: true,
//       transpilePackages: ['ui']
//     },
//     async headers() {
//       return [
//         {
//           source: '/:path*',
//           headers: createSecureHeaders({
//             forceHTTPSRedirect: dev
//               ? false
//               : [
//                   true,
//                   {
//                     maxAge: 60 * 60 * 24 * 365 * 2
//                     // includeSubDomains: true
//                   }
//                 ],
//             referrerPolicy: 'no-referrer-when-downgrade',
//             frameGuard: false
//           })
//         }
//       ]
//     }
//   }

//   // * development only config
//   if (phase === PHASE_DEVELOPMENT_SERVER) {
//     return { ...nextConfig }
//   }

//   return nextConfig
// }

// export default createConfig
